import { AppEnv, ClubApp, Environment } from '@aaa/emember/types';

/**
 * copy/pasted from firebase project settings
 */
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCUfa9QBZeDOBWvWyb6IIjHNcCSIWzqH4M',
  authDomain: 'aaa-shelbycounty-2.firebaseapp.com',
  databaseURL: 'https://aaa-shelbycounty-2-default-rtdb.firebaseio.com',
  projectId: 'aaa-shelbycounty-2',
  storageBucket: 'aaa-shelbycounty-2.appspot.com',
  messagingSenderId: '358837638764',
  appId: '1:358837638764:web:5fecf640e57ff523db6a49',
  measurementId: 'G-PG2D3PRKMQ',
};

export const environment: Environment = {
  production: true,
  emulator: false,
  ngServe: false,
  appEnv: AppEnv.TEST,
  cloudFunctionsURL: 'https://test.shelbycounty.millisite.com',
  cloudStorageURL: `https://storage.googleapis.com/${firebaseConfig.storageBucket}`,
  clubId: ClubApp.Shelby,
  firebaseConfig: firebaseConfig,
  SMARTY_WEBSITE_KEY: '',
  paymentConfig: {
    cyberSourceSessionTimeout: 60 * 1000 * 10, // ten minutes
  },
  aaaRestWsUrl: 'https://ww2.aaa.com',
  baseHref: 'https://test.shelbycounty.millisite.com/',
  zipcode: '45365',
};
